import React from "react";

import styles from "./Intro.module.css";

const Intro = () => (
  <div className={styles.Intro}>
    <h1 className={styles.IntroTitle}>
      The ultimate app for{" "}
      <span className={styles.IntroHighlighted}>progressive</span> trainers
    </h1>
    <div className={styles.IntroText}>
      FitLab gives coaches the ability to manage and track the progress of their
      clients.
      <br />
      <span className={styles.IntroTextFree}>
        The basic plan is free forever.
      </span>
    </div>
    <div className={styles.IntroButtons}>
      <a
        href="https://coach.thefitlab.io/account/signup"
        className={styles.IntroButton}
      >
        <span className={styles.IntroButtonText}>GET STARTED NOW</span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.5781 7.62187L7.675 2.49844C7.62969 2.45937 7.57188 2.4375 7.51094 2.4375H6.12813C6.0125 2.4375 5.95937 2.58125 6.04688 2.65625L11.5188 7.40625H2.375C2.30625 7.40625 2.25 7.4625 2.25 7.53125V8.46875C2.25 8.5375 2.30625 8.59375 2.375 8.59375H11.5172L6.04531 13.3438C5.95781 13.4203 6.01094 13.5625 6.12656 13.5625H7.55625C7.58594 13.5625 7.61562 13.5516 7.6375 13.5312L13.5781 8.37813C13.6322 8.33109 13.6756 8.27299 13.7053 8.20776C13.735 8.14253 13.7504 8.07168 13.7504 8C13.7504 7.92832 13.735 7.85747 13.7053 7.79224C13.6756 7.72701 13.6322 7.66891 13.5781 7.62187Z"
            fill="#fff"
          />
        </svg>
      </a>
      <div className={styles.IntroLabel}>NO CREDIT CARD REQUIRED</div>
    </div>
  </div>
);

export default Intro;
