import React from "react";

import SEO from "../components/seo";
import Layout from "../components/Layout";
import StreetArt from "../components/StreetArt";

import Info from "./index-page/Info";
import Intro from "./index-page/Intro";
import Details from "./index-page/Details";
import Features from "./index-page/Features";
import StartText from "./index-page/StartText";
import SignUpBanner from "./index-page/SignUpBanner";

import styles from "./index.module.css";

const IndexPage = () => (
  <Layout>
    <SEO title="Personal Trainer software - FitLab" />
    <section className={styles.IndexSectionIntro}>
      <Intro />
      <StreetArt />
    </section>
    <section className={styles.IndexSectionStartText}>
      <StartText />
    </section>
    <section className={styles.IndexSectionInfo}>
      <Info />
    </section>
    <section className={styles.IndexSectionFeatures} id="key">
      <Features />
    </section>
    <section className={styles.IndexSectionSignUp}>
      <SignUpBanner />
    </section>
    <section className={styles.IndexSectionDetails} id="how">
      <Details />
    </section>
  </Layout>
);

export default IndexPage;
